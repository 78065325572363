import { Dialog } from 'vant';
import 'vant/es/dialog/style';
import { paytmp2pupirefno, paytmp2pgetorder } from '../common/api';
export default {
  data() {
    return {
      showPopover: true,
      utrvalue: '',
      show: false,
      disabled: false
    };
  },

  created() {
    this.orderno = this.$router.currentRoute.value.params.orderno;
    paytmp2pgetorder({
      "orderno": this.orderno
    }).then(res => {
      if (res.code === 200) {
        this.$store.commit('setorderinfo', res.data);

        if (res.data.status === "success") {
          this.return_merchant_dialog("Order has been successfully paid");
        } else if (res.data.status === "pre" || res.data.status === "paying") {
          return true;
        } else {
          this.return_merchant_dialog("Order payment failed or timed out");
        }
      } else {
        this.return_merchant_dialog(res.errmsg);
      }
    }).catch(error => {
      console.log(error);
    });
  },

  methods: {
    closed() {
      console.log('closed');
      this.showPopover = true;
    },

    onFinish() {
      this.countDown.reset();
      this.show = false;
    },

    return_merchant_dialog(msg) {
      let that = this;
      Dialog.alert({
        title: 'Notice',
        message: msg,
        confirmButtonText: "Back to business"
      }).then(() => {
        window.location.href = that.$store.state.orderinfo.callback_url;
      });
    },

    submitutr() {
      if (String(this.utrvalue).length !== 12) {
        this.$toast.fail("Please enter a UPI Ref No with a length of 12 digits");
        return false;
      }

      this.disabled = true;
      paytmp2pupirefno({
        orderno: this.orderno,
        upi_ref_no: this.utrvalue
      }).then(res => {
        this.disabled = false;

        if (res.code == 200) {
          this.return_merchant_dialog("Success");
        } else {
          this.$toast.fail(res.errmsg);
        }
      });
    }

  }
};