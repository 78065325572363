import axios from "axios";

// axios.defaults.baseURL = 'https://api.ineedpay.com';
axios.defaults.baseURL = 'https://siiapi.in';
axios.defaults.headers["Content-Type"] = "multipart/form-data";

//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        config.headers["Accept-Language"] = localStorage.getItem("language") || "en-us";
        config.baseURL = axios.defaults.baseURL;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(error);
    }
);
export default {
    /**
     * @param {String} url 
     * @param {Object} data 
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'post',
                    url,
                    data: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'get',
                    url,
                    params: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};
