import QrcodeVue from 'qrcode.vue';
import moment from 'moment';
import { Dialog } from 'vant';
import html2canvas from 'html2canvas';
import { paytmp2pgetorder, process } from '../common/api';
export default {
  components: {
    QrcodeVue
  },

  data() {
    return {
      qvalue: 'upi://pay',
      size: 200,
      orderno: 'NA',
      amount: 0,
      posterContent: '',
      posterImg: '',
      iscanpay: true,
      orderinfo: {}
    };
  },

  created() {
    this.orderno = this.$router.currentRoute.value.params.orderno;
    this.post_process("qrcode");
    let that = this;
    paytmp2pgetorder({
      "orderno": that.orderno
    }).then(res => {
      if (res.code === 200) {
        that.$store.commit('setorderinfo', res.data);
        that.orderinfo = res.data;
        that.amount = that.formatNum(res.data.amount);
        that.qvalue = "upi://" + "pay?pa=" + that.orderinfo.collection_upi_id + "&pn=IPG&tr=" + that.orderno + "&tid=" + that.orderno + "&tn=" + that.orderno + "&am=" + that.amount + "&cu=INR";

        if (res.data.status === "success") {
          that.post_process("qrcodeordersuccess");
          that.return_merchant_dialog("Your order has been completed");
          that.posterContent = "Your order has been completed";
          that.qvalue = "Your order has been completed";
          that.iscanpay = false;
        } else if (res.data.status === "pre" || res.data.status === "paying") {
          setTimeout(function () {
            that.createPoster();
          }, 1000);
          return true;
        } else {
          that.post_process("ordertimeout");
          that.return_merchant_dialog("Order payment failed or timed out");
          that.posterContent = "Order payment failed or timed out";
          that.qvalue = "Order payment failed or timed out";
          that.iscanpay = false;
        }
      } else {
        that.post_process("paytmp2pgetorder-error");
        that.posterContent = res.errmsg;
        that.qvalue = res.errmsg;
        that.iscanpay = false;
        that.return_merchant_dialog(res.errmsg);
      }
    });
  },

  methods: {
    createPoster() {
      let vm = this;
      const domObj = document.getElementById('posterHtml');
      html2canvas(domObj, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,

        onclone(doc) {
          let e = doc.querySelector('#posterHtml');
          e.style.display = 'block';
        }

      }).then(canvas => {
        vm.posterImg = canvas.toDataURL('image/png');
        console.log(vm.posterImg); //vm.imgDown(vm.posterImg)
      });
    },

    // Download() {
    //   var type = 'png';
    //   var imgdata = this.posterImg;
    //   // 将mime-type改为image/octet-stream,强制让浏览器下载
    //   var fixtype = function (type) {
    //     type = type.toLocaleLowerCase().replace(/png/i, 'jpeg');
    //     var r = type.match(/png|jpeg|bmp|gif/)[0];
    //     return 'image/' + r;
    //   };
    //   imgdata = imgdata.replace(fixtype(type), 'image/octet-stream');
    //   // 将图片保存到本地
    //   var savaFile = function (data, filename) {
    //     var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    //     save_link.href = data;
    //     save_link.download = filename;
    //     var event = document.createEvent('MouseEvents');
    //     event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    //     save_link.dispatchEvent(event);
    //   };
    //   var filename = this.orderinfo.merchant_orderno + '.' + type;
    //   savaFile(imgdata, filename);
    // },
    // 保存图片到本地
    imgDown() {
      if (!this.iscanpay) {
        this.return_merchant_dialog("Order payment failed or timed out");
      }

      if (this.posterImg === '') {
        this.createPoster();
      }

      var alink = document.createElement('a');
      alink.href = this.posterImg;
      alink.download = this.orderinfo.merchant_orderno + '.png'; // 图片名

      alink.click();
    },

    post_process(process_text) {
      let timenow = moment(new Date()).format('YYYY/MM/DD HH:mm:ss');
      process({
        "orderno": this.orderno,
        "process": " [" + timenow + "]" + process_text
      });
    },

    return_merchant_dialog(msg) {
      this.post_process("showdialog");
      Dialog.close();
      let that = this;
      Dialog.alert({
        title: 'Notice',
        message: msg,
        confirmButtonText: "Done"
      }).then(() => {
        that.post_process("callback");
      });
    },

    formatNum(num) {
      var value = Math.round(parseFloat(num) * 100) / 100;
      var arrayNum = String(value).split(".");

      if (arrayNum.length == 1) {
        return String(value) + ".00";
      }

      if (arrayNum.length > 1) {
        if (arrayNum[1].length < 2) {
          return String(value) + "0";
        }

        return value;
      }
    }

  }
};