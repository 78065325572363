import { Dialog } from 'vant';
import useClipboard from "vue-clipboard3";
import 'vant/es/dialog/style';
import { paytmp2pupirefno, paytmp2pgetorder, process, paytmp2pquery, paytmp2pexecuteorder } from '../common/api';
export default {
  data() {
    return {
      showPopover: true,
      utrvalue: '',
      show: false,
      disabled: false,
      orderno: '',
      querytimes: 0,
      queryinterval: "",
      orderinfo: {}
    };
  },

  created() {
    this.orderno = this.$router.currentRoute.value.params.orderno;
    this.post_process("viewutr");
    paytmp2pgetorder({
      "orderno": this.orderno
    }).then(res => {
      if (res.code === 200) {
        this.orderinfo = res.data;
        this.$store.commit('setorderinfo', res.data);

        if (res.data.status === "success") {
          this.post_process("ordersuccess");
          this.return_merchant_dialog("Order has been successfully paid");
        } else if (res.data.status === "pre" || res.data.status === "paying") {
          if (res.data.status === "pre") {
            this.executeorder();
          }

          return true;
        } else {
          this.post_process("ordertimeout");
          this.return_merchant_dialog("Order payment failed or timed out");
        }
      } else {
        this.return_merchant_dialog(res.errmsg);
      }
    }).catch(error => {
      console.log(error);
    });
    this.queryinterval = setInterval(this.query, 2000);
  },

  methods: {
    copy(text) {
      try {
        const {
          toClipboard
        } = useClipboard();
        toClipboard(text);
        this.$toast("Copy success");
      } catch (e) {
        this.$toast.fail("Copy fail");
      }

      this.post_process("copy");
    },

    executeorder() {
      paytmp2pexecuteorder({
        "orderno": this.orderno
      }).then(res => {
        if (res.code === 200) {
          this.post_process("executeorder");
        } else {
          this.return_merchant_dialog(res.errmsg);
        }
      });
    },

    query() {
      let that = this;
      paytmp2pquery({
        orderno: this.orderno
      }).then(res => {
        if (res.code == 200) {
          this.querytimes = this.querytimes + 1;

          if (res.data.status === "success") {
            clearInterval(this.queryinterval);
            setTimeout(function () {
              that.post_process("ordersuccess");
              that.callback();
            }, 1000);
            return;
          }

          if (res.data.status === "fail") {
            that.post_process("orderfail");
            clearInterval(this.queryinterval);
            setTimeout(function () {
              that.callback();
            }, 1000);
            return;
          }

          if (this.querytimes > 60) {
            return;
          }
        }
      });
    },

    callback() {
      this.post_process("utrcallback");
      window.location.href = this.$store.state.orderinfo.callback_url;
    },

    post_process(process_text) {
      process({
        "orderno": this.orderno,
        "process": process_text
      });
    },

    closed() {
      console.log('closed');
      this.showPopover = true;
    },

    onFinish() {
      this.countDown.reset();
      this.show = false;
    },

    return_merchant_dialog(msg) {
      let that = this;
      Dialog.alert({
        title: 'Notice',
        message: msg,
        confirmButtonText: "Back to business"
      }).then(() => {
        that.post_process("utrcallback");
        window.location.href = that.$store.state.orderinfo.callback_url;
      });
    },

    submitutr() {
      console.log(this.utrvalue);

      if (String(this.utrvalue).length !== 12) {
        this.$toast.fail("Please enter a UPI Ref No with a length of 12 digits");
        return false;
      }

      this.post_process("submitutr");
      this.disabled = true;
      paytmp2pupirefno({
        orderno: this.orderno,
        upi_ref_no: this.utrvalue
      }).then(res => {
        this.disabled = false;

        if (res.code == 200) {
          this.return_merchant_dialog("Success");
        } else {
          this.$toast.fail(res.errmsg);
        }
      });
    }

  },

  unmounted() {
    clearInterval(this.queryinterval);
  }

};