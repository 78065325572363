import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SuccessView from '../views/SuccessView.vue';
import ErrorView from '../views/ErrorView.vue';
import BharatView from '../views/BharatView.vue';
import QrcodeView from '../views/QrcodeView.vue';
const routes = [
    {
        path: '/:orderno',
        name: 'home',
        component: HomeView
    },
    {
        path: '/success/:orderno',
        name: 'success',
        component: SuccessView
    },
    {
        path: '/qrcode/:orderno',
        name: 'qrcode',
        component: QrcodeView
    },
    {
        path: '/error/:orderno',
        name: 'error',
        component: ErrorView
    },
    {
        path: '/bharat/:orderno',
        name: 'bharat',
        component: BharatView
    },
]


const router = createRouter({
    history: createWebHistory(),
    //history: createWebHashHistory(process.env.BASE_URL),
    routes
});

export default router