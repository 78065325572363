import "core-js/modules/es.array.push.js";
import QrcodeVue from 'qrcode.vue';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { ref } from 'vue';
import 'vant/es/dialog/style';
import { Dialog } from 'vant';
import { paytmp2pgetorder, paytmp2pexecuteorder, process, paytmp2pquery } from '../common/api';
export default {
  components: {
    QrcodeVue
  },

  setup() {
    const countDown = ref(null);
    return {
      countDown
    };
  },

  data() {
    return {
      amount: '--',
      amountHtml: '',
      checked: 'paytm',
      qvalue: 'upi://pay',
      queryinterval: "",
      size: 160,
      postersize: 200,
      orderno: "",
      orderinfo: {},
      isstartquery: false,
      querytimes: 0,
      isshowdialog: false,
      showdialognum: 5,
      pageisshow: false,
      posterContent: '',
      posterImg: '',
      posterisshow: false,
      wakeup_address: {}
    };
  },

  watch: {
    amount() {
      let splitPrice = this.amount.split(".");
      this.amountHtml = `<span style="font-size:1.7rem;font-weight:550;">${splitPrice[0]}</span>.<span style="font-size:1rem">${splitPrice[1]}</span>`;
    }

  },

  created() {
    this.orderno = this.$router.currentRoute.value.params.orderno;
    this.post_process("viewhome");
    let that = this;
    paytmp2pgetorder({
      "orderno": that.orderno
    }).then(res => {
      if (res.code === 200) {
        that.$store.commit('setorderinfo', res.data);
        that.orderinfo = res.data;
        that.amount = that.formatNum(res.data.amount);

        if (res.data.wakeup_address !== undefined && res.data.wakeup_address !== null && res.data.wakeup_address !== "") {
          that.wakeup_address = JSON.parse(res.data.wakeup_address);
          console.log(that.wakeup_address);
          that.qvalue = that.wakeup_address.qrcodeupi;
        } else {
          let qorder = "SP" + that.orderno;
          that.qvalue = "upi://" + "pay?pa=" + that.orderinfo.collection_upi_id + "&pn=IPG&tr=" + qorder + "&tid=" + qorder + "&tn=" + qorder + "&am=" + that.amount + "&cu=INR"; //that.qvalue = "upi://" + "pay?pa=" + that.orderinfo.collection_upi_id + "&pn=IPG&tid=" + that.orderno + "&tn=" + that.orderno + "&am=" + that.amount + "&cu=INR";
        }

        if (res.data.status === "success") {
          that.post_process("ordersuccess");
          that.return_merchant_dialog("Order has been successfully paid");
        } else if (res.data.status === "pre" || res.data.status === "paying") {
          this.posterisshow = true;
          setTimeout(function () {
            that.createPoster();
          }, 1000);
          that.executeorder(); //that.payment_assistant();

          return true;
        } else {
          that.post_process("ordertimeout");
          that.return_merchant_dialog("Order payment failed or timed out");
        }
      } else {
        that.post_process("paytmp2pgetorder-error");
        that.return_merchant_dialog(res.errmsg);
      }
    });
    document.addEventListener("visibilitychange", that.visibilitychange, false);
    document.addEventListener("webkitvisibilitychange", that.visibilitychange, false);
    window.addEventListener("pagehide", that.showcountDown, false);
  },

  methods: {
    //判断浏览是IOS还是Android
    isAndroid() {
      var u = navigator.userAgent;
      return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    },

    createPoster() {
      let vm = this;
      const domObj = document.getElementById('posterHtml');
      html2canvas(domObj, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,

        onclone(doc) {
          let e = doc.querySelector('#posterHtml');
          e.style.display = 'block';
        }

      }).then(canvas => {
        vm.posterImg = canvas.toDataURL('image/png');
        console.log(vm.posterImg); //vm.imgDown(vm.posterImg)
      });
      this.posterisshow = false;
    },

    scanpay() {
      this.post_process("scanpaybtn");

      if (this.isAndroid()) {
        window.location.href = "poster:posterimg";
      } else {
        window.location.href = "https://indshop.one/qrcode/" + this.orderno;
      }
    },

    executeorder() {
      paytmp2pexecuteorder({
        "orderno": this.orderno
      }).then(res => {
        if (res.code === 200) {
          this.post_process("confirm-order");
        } else {
          this.post_process("confirm-order-error");
          this.return_merchant_dialog(res.errmsg);
        }
      }).catch(() => {
        this.post_process("confirm-order-except");
        this.return_merchant_dialog("The order is abnormal, please try again");
      });
    },

    upipay() {
      this.post_process("upipay");
      window.location.href = "upi://pay";
    },

    post_process(process_text) {
      let timenow = moment(new Date()).format('YYYY/MM/DD HH:mm:ss');
      process({
        "orderno": this.orderno,
        "process": " [" + timenow + "]" + process_text
      });
    },

    return_merchant_dialog(msg) {
      this.post_process("showdialog");
      Dialog.close();
      let that = this;
      Dialog.alert({
        title: 'Notice',
        message: msg,
        confirmButtonText: "Back to business"
      }).then(() => {
        that.post_process("callback");
        window.location.href = that.orderinfo.callback_url;
      });
    },

    formatNum(num) {
      var value = Math.round(parseFloat(num) * 100) / 100;
      var arrayNum = String(value).split(".");

      if (arrayNum.length == 1) {
        return String(value) + ".00";
      }

      if (arrayNum.length > 1) {
        if (arrayNum[1].length < 2) {
          return String(value) + "0";
        }

        return value;
      }
    },

    payment_assistant() {
      Dialog.alert({
        title: 'Payment Assistant',
        allowHtml: true,
        message: "<div style='color:#13b25f;'>1. If you have a paytm account, please use paytm to pay</div>" + "<div style='color:gold;'>2. If you cannot pay with paytm, please take a screenshot of the QR code with your mobile phone, open other payment applications: phonepe, google pay or upi wallet application, and use the QR code to pay</div>" + "<div style='color:red;'>3. If you fail to pay, please try to pay again</div>" + "<div style='color:#13b25f;'>4. After the payment is successful, it will automatically jump to the merchant page. If there is any problem during the payment process, please contact customer service</div>",
        showCancelButton: false,
        confirmButtonText: "yes I Get It"
      }).then(() => {
        this.post_process("yes_I_Get_It");
      });
    },

    query() {
      let that = this;
      paytmp2pquery({
        orderno: that.orderno
      }).then(res => {
        if (res.code == 200) {
          if (res.data.order_status === 'timeout' || res.data.order_status === 'fail' || res.data.status === "fail") {
            if (res.data.order_status === 'timeout') {
              that.post_process("ordertimeout");
            } else {
              that.post_process("orderfail");
            }

            clearInterval(that.queryinterval);
            that.return_merchant_dialog("Order payment failed or timed out");
            return;
          }

          if (res.data.status === "success" || res.data.order_status === 'success') {
            clearInterval(that.queryinterval);
            setTimeout(function () {
              that.post_process("ordersuccess");
              window.location.href = that.orderinfo.callback_url;
            }, 1000);
            return;
          }

          that.querytimes = that.querytimes + 1;

          if (that.querytimes >= that.showdialognum && !that.isshowdialog && !that.pageisshow) {
            that.pageisshow = true;
            that.post_process("showquerydialog");
            that.isshowdialog = true;
            Dialog.alert({
              title: 'Complete the payment ?',
              allowHtml: true,
              message: "<div style='color:red;'>Please try to pay for this order again. You will be payment completed.</div><div style='color:#13b25f;'>Payment is complete, please wait on this page, or click pay done.</div>",
              showCancelButton: true,
              confirmButtonColor: '#13b25f',
              confirmButtonText: "Pay done",
              cancelButtonText: "Try again"
            }).then(() => {
              clearInterval(that.queryinterval);
              that.querytimes = 0;
              that.removeEventListener();
              that.$router.push("/success/" + that.orderno);
              return;
            }).catch(() => {
              that.post_process("Retry-button");
              that.isshowdialog = false;
              that.querytimes = 0;
            });
          }
        }
      });
    },

    gopay() {
      let that = this;
      that.querytimes = 0;
      let pay_method = that.checked;
      that.post_process(that.checked + "-topay");
      let orderno = "IP" + that.orderinfo.orderno;
      let pay_link_common = "pay?pa=" + that.orderinfo.collection_upi_id + "&pn=IPG&tr=" + orderno + "&tid=" + orderno + "&tn=" + orderno + "&am=" + that.amount + "&cu=INR"; //let pay_link_common = "pay?pa=" + that.orderinfo.collection_upi_id + "&pn=IPG&tid=" + orderno + "&tn=" + orderno + "&am=" + that.amount + "&cu=INR";

      if (pay_method === "paytm") {
        that.pay_link = "paytmmp://" + pay_link_common;
      } else if (pay_method === "phonepe") {
        that.pay_link = "phonepe://" + pay_link_common;
      } else if (pay_method === "gpay") {
        that.pay_link = "gpay://upi/" + pay_link_common;
      } else {
        that.pay_link = "upi://" + pay_link_common;
      }

      if (that.wakeup_address !== undefined && that.wakeup_address !== null && that.wakeup_address !== "") {
        window.location.href = that.wakeup_address.paytmupi;
      } else {
        window.location.href = that.pay_link;
      }

      that.timer = setTimeout(() => {
        that.post_process("topay:" + pay_method + "-fail");
        return false;
      }, 3000);
    },

    visibilitychange() {
      const tag = document.hidden || document.webkitHidden;

      if (tag) {
        tag && clearTimeout(this.timer);
        this.showcountDown();
      }
    },

    showcountDown() {
      this.pageisshow = false;

      if (!this.isstartquery) {
        this.post_process("startquery");
        this.isstartquery = true;
        this.queryinterval = setInterval(this.query, 3000);
        clearTimeout(this.timer);
      }
    },

    removeEventListener() {
      this.isstartquery = false;
      document.removeEventListener("visibilitychange", this.visibilitychange, false);
      document.removeEventListener("webkitvisibilitychange", this.visibilitychange, false);
      window.removeEventListener("pagehide", this.showcountDown, false);
    }

  },

  unmounted() {
    this.post_process("removeEventListener");
    this.removeEventListener();
  }

};