import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import enUS from 'vant/es/locale/lang/en-US';
import { Locale } from 'vant';
import { Toast } from 'vant';
import 'vant/es/toast/style';

Locale.use('en-US', enUS);
const messages = {
    'en-US': {
        tel: 'Ratio',
        name: "Code",
    },
};
Locale.add(messages);
createApp(App)
    .use(router)
    .use(store)
    .use(Toast)
    .mount('#app')
