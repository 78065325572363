import axios from "./http";
let {get, post } = axios

// order
export const paytmp2pexecuteorder = data => post("/mcapi/paytmp2pexecuteorder/", data)

// getorder
export const paytmp2pgetorder = data => get("/mcapi/paytmp2pgetorder/", data)

// upirefno
export const paytmp2pupirefno = data => post("/mcapi/paytmp2pupirefno/", data)

// query
export const paytmp2pquery = data => get("/mcapi/paytmp2pquery/", data)

// process
export const process = data => post("/mcapi/process/", data)